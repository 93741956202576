<template>
  <div class="integral-detail-con">
    <NavBar title="订单详情" fixed left-arrow @click-left="goBack" v-if="isWeixin_status == false"></NavBar>
    <!-- 积分兑换详情 -->
    <template v-if="$route.query.type ==0">
      <swipe v-if="picArr.length" :autoplay="3000" indicator-color="white" :height="180">
        <swipe-item v-for="image in picArr" :key="image">
          <img width="100%" height="180rem" fit="cover" v-lazy="image" />
        </swipe-item>
      </swipe>
      <div class="detail-title">
        <div class="detail-title-top">
          <div>
            <span>订单号: {{InfoObj.order_sn}}</span>
            <tag v-if="InfoObj.status == 0" mark>未核销</tag>
            <tag v-else mark type="primary">已核销</tag>
          </div>
          <span>消耗积分: {{InfoObj.total_credit}}</span>
          <span>兑换数量: {{InfoObj.num}}</span>
        </div>
        <div class="detail-title-bottom">
          <span>创建时间</span>
          <span>{{InfoObj.create_time}}</span>
        </div>
      </div>
      <Panel title="商品介绍" v-if="InfoObj.goods">
        <div v-html="InfoObj.goods.brife" class="integral-brife"></div>
      </Panel>
      <Panel title="发货信息" style="margin-top: 10px" v-if="InfoObj.status==3">
        <cell-group>
          <cell title="收件人" :value="InfoObj.address.name" />
          <cell title="手机号" :value="InfoObj.address.mobile" />
          <cell title="地址" :value="`${InfoObj.address.area_name}${InfoObj.address.address}`" />
          <cell title="快递公司" :value="InfoObj.express.logi_name" />
          <cell
            title="快递单号"
            :value="InfoObj.send_num"
            label="点击复制单号去查询"
            v-clipboard:copy="InfoObj.send_num"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          />
        </cell-group>
      </Panel>
      <!-- v-if="InfoObj.status == 3" -->

      <Panel :title=" '核销码:' + InfoObj.cancel_code" v-if="InfoObj.status == 0">
        <div class="integral-brife">
          <img class="img-qrcode" :src="qr_img" alt />
        </div>
      </Panel>
    </template>
    <!-- 抽奖发货详情 -->
    <template v-else>
      <swipe :autoplay="3000" indicator-color="white" :height="180">
        <swipe-item v-for="image in picArr" :key="image">
          <img width="100%" height="180rem" fit="cover" v-lazy="image" />
        </swipe-item>
      </swipe>
      <div class="detail-title">
        <div class="detail-title-top" style="height:140px">
          <div>
            <span>订单号: {{InfoObj.order_num}}</span>
            <!-- <tag v-if="InfoObj.status == 0" mark>未核销</tag>
            <tag v-else mark type="primary">已核销</tag> -->
          </div>
          <span>奖项等级：{{InfoObj.products.level}}</span>
          <span>奖品等级：{{InfoObj.products.name}}</span>
          <span>消耗积分: {{InfoObj.products.bigwheel.score}}</span>
          <span>中奖数量: {{InfoObj.products.num}}</span>
        </div>
        <div class="detail-title-bottom">
          <span>中奖时间</span>
          <span>{{InfoObj.create_time}}</span>
        </div>
      </div>

      <Panel title="发货信息"  style="margin-top: 10px" v-if="InfoObj.status == 1">
        <cell-group>
          <cell title="收件人" :value="InfoObj.address.name" />
          <cell title="手机号" :value="InfoObj.address.mobile" />
          <cell title="地址" :value="`${InfoObj.address.area_name}${InfoObj.address.address}`" />
          <cell title="快递公司" :value="InfoObj.express.logi_name" />
          <cell
            title="快递单号"
            :value="InfoObj.send_num"
            label="点击复制单号去查询"
            v-clipboard:copy="InfoObj.send_num"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          />
        </cell-group>
      </Panel>
    </template>
  </div>
</template>
<script>
import { Swipe, SwipeItem, Panel, NavBar, tag, Cell, CellGroup } from "vant";
import CreditShop from "@/api/creditshop";
import QRCode from 'qrcode';
export default {
  components: {
    Swipe,
    SwipeItem,
    Panel,
    NavBar,
    tag,
    Cell,
    CellGroup
  },
  data() {
    return {
      images: [],
      InfoObj: {},
      picArr: [],
      qr_img: ""
    };
  },
  created() {
    if (this.$route.query.type == 0) {
      this.getInfoDetail(this.$route.query.id);
    } else {
      this.getawardDetail(this.$route.query.id)
    }
    
  },
  methods: {
      // 复制成功
    onCopy() {
      this.$toast("已复制到剪切板");
    },
    // 复制失败
    onError(e) {
      alert(e,"失败");
    },
    async generateQR(url) {
      try {
        this.qr_img = await QRCode.toDataURL(url)
      } catch (err) {
        this.$toast('生成二维码失败');
        console.error(err)
      }
    },
    async getInfoDetail(idx) {
      try {
        const res = await CreditShop.getExChangeDetail(idx);
        if (res.code == 200) {
          this.InfoObj = res.data;
          await this.generateQR(res.data.cancel_code)
          if (res.data.goods) {
            this.picArr.push(res.data.goods.logo);
          }
        } else {
          throw res.msg;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getawardDetail(idx) {
      try {
        const res = await CreditShop.awardDetail(idx);
        if (res.code == 200) {
          this.InfoObj = res.data;
          this.picArr.push(res.data.products.cover);
        } else {
          throw res.msg;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.integral-detail-con {
  width: 100%;
  height: 100vh;
  padding-top: 46px;
  background: rgba(244, 244, 244, 1);
  overflow-y: scroll;
  .detail-title {
    width: 100%;
    height: auto;
    padding: 10px 4px 6px;
    background: #67c23a;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .detail-title-top {
      width: 100%;
      height: 80px;
      display: flex;
      color: #ffffff;
      padding-left: 10px;
      padding-bottom: 10px;
      flex-direction: column;
      justify-content: space-around;
      div {
        display: flex;
        justify-content: space-between;
      }
      span:nth-child(1) {
        font-size: 14px;
      }
      span:nth-child(2) {
        font-size: 12px;
        color: #dfdfdf;
      }
    }

    .detail-title-bottom {
      flex: 1;
      border-radius: 3px;
      padding: 0 20px;
      display: flex;
      font-size: 16px;
      align-items: center;
      justify-content: space-between;
      background: #ffffff;
      span:nth-child(1) {
        color: #67c23a;
      }
      span:nth-child(2) {
        color: #696969;
        font-size: 12px;
      }
    }
  }
  .integral-brife {
    padding: 10px;
    .img-qrcode {
      width: 100%;
    }
  }
  .exchange-bot {
    width: 100%;
    height: 110px;
    padding: 0 20px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    .exchange-bot-left {
      width: 70%;
      height: 90%;
      display: flex;
      div:nth-child(1) {
        width: 60px;
        height: 60px;
        img {
          max-width: 100%;
          height: auto;
        }
      }
      div:nth-child(2) {
        flex: 1;
        height: 60px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-left: 10px;
        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
}
</style>